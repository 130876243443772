import React, { useEffect, useRef } from 'react';
import * as THREE from 'three';
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';

const ThreeJSRenderer = ({ objString }) => {
  const mountRef = useRef(null);

  useEffect(() => {
    const currentMount = mountRef.current;
    const maxCanvasWidth = 1600;
  
    const scene = new THREE.Scene();
  
    // Kamera sa širim vidnim poljem i odgovarajućim razmerama
    const camera = new THREE.PerspectiveCamera(75, Math.min(window.innerWidth, maxCanvasWidth) / 500, 0.1, 1000);
    camera.position.set(0, 0, 3); // Približavamo kameru objektu
  
    // Renderer sa antialiasing opcijom za glatke ivice
    const renderer = new THREE.WebGLRenderer({ antialias: true });
    const canvasWidth = Math.min(window.innerWidth, maxCanvasWidth);
    renderer.setSize(canvasWidth, 500); // Postavljamo visinu canvasa na 500px
    renderer.setPixelRatio(window.devicePixelRatio); // Dodato za optimizaciju prikaza na mobilnim uređajima
    currentMount.appendChild(renderer.domElement);
  
    // Osvetljenje scene
    const ambientLight = new THREE.AmbientLight(0x404040, 1.5); // Mekano svetlo
    scene.add(ambientLight);
  
    const directionalLight = new THREE.DirectionalLight(0xffffff, 1);
    directionalLight.position.set(10, 10, 10).normalize(); // Svetlo koje dolazi iz određene pozicije
    scene.add(directionalLight);
  
    // Učitavanje i dodavanje OBJ objekta
    const loader = new OBJLoader();
    const obj = loader.parse(objString);
    obj.scale.set(1.5, 1.5, 1.5); // Uvećavamo objekat da bude bolje vidljiv
    obj.position.set(0, 0, 0); // Postavljamo objekat u centar scene
  
    // Obojeni materijal za objekat
    obj.traverse(function (child) {
      if (child instanceof THREE.Mesh) {
        child.material = new THREE.MeshStandardMaterial({ color: 0x0077ff }); // Postavljamo plavu boju na materijal
      }
    });
  
    scene.add(obj);
  
    // Dodavanje OrbitControls za interaktivnu kontrolu
    const controls = new OrbitControls(camera, renderer.domElement);
    controls.enableDamping = true; // Uključuje damping (za glatke pokrete)
    controls.dampingFactor = 0.05;
    controls.screenSpacePanning = false; // Onemogućava panning
    controls.maxPolarAngle = Math.PI / 2; // Ograničava vertikalnu rotaciju
  
    // Animacija sa rotacijom objekta
    const animate = function () {
      requestAnimationFrame(animate);
      controls.update(); // Ažurira kontrole
      renderer.render(scene, camera);
    };
  
    animate();
  
    // U slučaju promene veličine prozora, ažuriramo kameru i renderer
    const handleResize = () => {
      const canvasWidth = Math.min(window.innerWidth, maxCanvasWidth);
      camera.aspect = canvasWidth / 500;
      camera.updateProjectionMatrix();
      renderer.setSize(canvasWidth, 500);
    };
    window.addEventListener('resize', handleResize);
  
    return () => {
      window.removeEventListener('resize', handleResize);
      currentMount.removeChild(renderer.domElement);
    };
  }, [objString]);

  return <div ref={mountRef}></div>;
};

export default ThreeJSRenderer;
